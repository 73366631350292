<template>
    <div class="d-flex flex-wrap mb-5">
        <div class="col-12">
            <h1 class="text-primary">Felicitaciones 🎉 !</h1>
        </div>
        <div class="col-12 mt-2">
            <p class="text-justify">
                Ya has aprendido a registrar inventario y ventas en tu comercio.
                Estamos muy felices que hagas parte de este proyecto y esperamos
                que con tu ayuda podamos seguir creciendo.
                <br />
                <br />
                Puedes explorar las demás funciones que tenemos disponibles,
                como la carga múltiple de inventario a través de un archivo
                Excel, el registro de servicios, la creación de productos nuevos
                que aún no estén registrados, y la opción de invitar a tus
                empleados a formar parte de la tienda.
            </p>
        </div>
        <div class="col-12 d-flex justify-content-center mt-3">
            <vs-button size="large" @click="$router.push('/app')">
                Explorar Funciones
            </vs-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "OnboardingFinish"
};
</script>
